import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { href_home } from 'components/href-helper';

import './style.less';

const BreadCrumbs = (props) => (
    <div className="bread-crumbs">
        <a href={href_home()}>首页</a>

        {props.content.map((item, index) => (
            <Fragment key={index}> / {item}</Fragment>
        ))}
    </div>
);

BreadCrumbs.defaultProps = {
    content: []
};

BreadCrumbs.propTypes = {
    content: PropTypes.array
};

export { BreadCrumbs };

import React, { Component, createRef, Fragment } from 'react';

/**
 * 底部吸附组件
 */
class FooterSticky extends Component {

    /**
     * @param {Element} element
     * @param {function} callback
     */
    static observeSticky(element, callback) {

        if (!window.IntersectionObserver) {
            console.log('FooterSticky 组件依赖 IntersectionObserver 实现一个增强 sticky 体验，当前 runtime 不支持，如需更好体验，请对 IntersectionObserver 进行 polyfill');
            return {
                observe() {},
                unobserve() {}
            };
        }

        let threshold = [];

        for (let i = 0; i <= 1.0; i += 0.01) {
            threshold.push(i);
        }

        const observer = new IntersectionObserver((records, observer) => {
            for (const record of records) {

                const { boundingClientRect, rootBounds } = record;

                let isSticky = false;

                // 如果容器底部超出视窗高度，则为 sticky 模式
                isSticky = boundingClientRect.bottom > rootBounds.height;

                callback(isSticky);

            }
        }, { threshold });

        observer.observe(element);

        return observer;
    }

    constructor(props) {
        super(props);

        this.originRef = createRef();

        this.state = {
            isSticky: false
        };

        this.onStickyChange = this.onStickyChange.bind(this);
    }

    componentDidMount() {
        const { parentElement } = this.originRef.current;
        this.observer = FooterSticky.observeSticky(parentElement, this.onStickyChange);

    }

    componentWillUnmount() {
        const { parentElement } = this.originRef.current;
        this.observer.unobserve(parentElement);
    }

    onStickyChange(isSticky) {
        this.setState({ isSticky });
    }

    render() {

        const { props, state } = this;

        const style = {};

        // 由于 .footer-sticky 的背景与父元素背景一致
        // 当触发 sticky 行为的时候，添加一个阴影用于区分
        if (state.isSticky) {
            style['boxShadow'] = '0px -1px 3px 0px rgba(0,0,0,0.1)';
        }

        return (
            <Fragment>
                <div className="footer footer-sticky" style={style} ref={this.originRef}>
                    {props.children}
                </div>

            </Fragment>


        );

    }

}

export { FooterSticky };
